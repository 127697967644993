import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import Grid from "../../../../../components/grid"
import Note from "../../../../../components/note"
import NotationGroup from "../../../../../components/notation-group"
import NotationSelectionSolution from "../../../../../components/notation-selection-solution"
import CourseFooterNext from "../../../../../components/course-footer-next"
import PoemMelodieNotation from "../../../../../content/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/poem-melodie-notation"
import FeebackHeaderProgress from "../../../../../components/feedback-header-progress"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"

const Page = () => {
  const { answerStatusForDict, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })
  const task = {
    courseId: "hoelderlins-reisen",
    chapterId: "02-reiseerinnerung",
    taskId: "melodie-des-gedichts",
  }
  const comparator = ({ answer, solution, status }) => {
    if (solution) {
      if (!answer?.selection) {
        return status.MISSED
      }
      if (answer?.selection === solution) {
        return status.VALID_CHECKED
      }
      return status.INVALID
    } else {
      if (answer?.selection) {
        return status.INVALID
      }
      return status.VALID_UNCHECKED
    }
  }
  const answers = answerStatusForDict({
    ...task,
    comparator,
  })
  const statistics = getStatsForTaskWithDict({
    ...task,
    comparator,
  })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/02-reiseerinnerung/neologismen" />
      }
      navigation={navigation}
    >
      <Seo title="Das Gedicht und seine Melodie" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Das Gedicht und seine Melodie
          </Heading>

          <FeebackHeaderProgress
            variant="solution"
            correct={statistics.correct}
            total={
              statistics.correct + statistics.missed + statistics.incorrect
            }
          />
          <Grid columns={[1, 2]}>
            <Stack space={3}>
              <Heading as="h3" level={6}>
                Deine Antwort
              </Heading>
              <NotationGroup initialState={answers}>
                <PoemMelodieNotation
                  showAnswer
                  notationType={NotationSelectionSolution}
                />
              </NotationGroup>
            </Stack>
            <Stack space={3}>
              <Heading as="h3" level={6}>
                Lösung
              </Heading>
              <NotationGroup initialState={answers}>
                <PoemMelodieNotation notationType={NotationSelectionSolution} />
              </NotationGroup>
            </Stack>
          </Grid>
          <Note>
            Hölderlin beginnt mit einer Reihe kurzer Wörter. Sie bestehen alle
            nur aus 1 oder 2 Silben. Doch dann folgen darauf 2 sehr lange
            zusammengesetzte Wörter: Vaterlandsstädte und Ländlichschönste. Ein
            echter Zungenbrecher! Damit bekommt das Gedicht einen ganz
            besonderen Rhythmus.
          </Note>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
