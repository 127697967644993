import React from "react"
import PropTypes from "prop-types"
import Poem from "../../../../components/poem"
import NotationSelection from "../../../../components/notation-selection"
import PaperStack from "../../../../components/paper-stack"

const PoemMelodieNotationSelection = ({
  size,
  notationType: NotationType,
  showAnswer,
}) => {
  return (
    <PaperStack>
      <Poem size={size}>
        <p>
          <NotationType showAnswer={showAnswer} id="q-01">
            Lange
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-02">
            lieb
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-03">
            ich
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-04">
            dich
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-05">
            schon,
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-06">
            möchte
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-07">
            dich
          </NotationType>
          ,{" "}
          <NotationType showAnswer={showAnswer} id="q-08">
            mir
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-09">
            zur
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-10">
            Lust
          </NotationType>
          ,
        </p>
        <p>
          <NotationType showAnswer={showAnswer} id="q-11">
            Mutter
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-12">
            nennen
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-13">
            und
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-14">
            dir
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-15">
            schenken
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-16">
            ein
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-17">
            kunstlos
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-18">
            Lied
          </NotationType>
          ,
        </p>
        <p>
          <NotationType showAnswer={showAnswer} id="q-19">
            Du
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-20">
            der
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-21">
            Vaterlandsstädte
          </NotationType>
        </p>
        <p>
          <NotationType showAnswer={showAnswer} id="q-22">
            Ländlichschönste
          </NotationType>
          ,{" "}
          <NotationType showAnswer={showAnswer} id="q-23">
            so
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-24">
            viel
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-25">
            ich
          </NotationType>{" "}
          <NotationType showAnswer={showAnswer} id="q-26">
            sah.
          </NotationType>
        </p>
      </Poem>
    </PaperStack>
  )
}

PoemMelodieNotationSelection.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  notationType: PropTypes.elementType,
  showAnswer: PropTypes.bool,
}

PoemMelodieNotationSelection.defaultProps = {
  size: [3, 3, 4],
  notationType: NotationSelection,
  showAnswer: false,
}

export default PoemMelodieNotationSelection
